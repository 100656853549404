import React, { Component } from 'react';

import TeamItem from './TeamItem';
import '../assets/css/_team.scss';

class Team extends Component {
    constructor(props) {
        super(props);
        this.teams = [
            {id: 1, name: "Rohollah Rohparwar", position: "Inhaber & Gesellschafter", image: "https://cms.goldenlegprojects.com/rohollah-rohparwar.jpg"},
            {id: 2, name: "Issa Saleh", position: "Gründer & Hauptprojektmanager", image: "https://cms.goldenlegprojects.com/issa-saleh.jpg"},
            {id: 3, name: "Daud Saleh", position: "Projektleitung Schwerin", image: "https://cms.goldenlegprojects.com/daud-saleh.jpg"},
            {id: 4, name: "Mariam Saleh-Rohparwar", position: "Projektleitung international", image: "https://cms.goldenlegprojects.com/team-4.jpg"},
            {id: 5, name: "Sandy Völzer", position: "Assistentin der Geschäftsführung", image: "https://cms.goldenlegprojects.com/sandy-voelzer.jpg"},
            {id: 6, name: "Josi Otto", position: "Betriebsleiterin & Veranstaltungsmanagerin", image: "https://cms.goldenlegprojects.com/josi-otto.jpg"},
            {id: 7, name: "Björn Schwalenberg", position: "Projektleitung Hamburg", image: "https://cms.goldenlegprojects.com/bjorn-schwalenberg.jpg"},
            {id: 8, name: "Kameliya Evtimova Kirilova", position: "Restaurantmanagerin", image: "https://cms.goldenlegprojects.com/kameliya.jpg"},
            {id: 9, name: "Hammoud Al Haj Khalaf", position: "Tresen- und Barkeeperchef", image: "https://cms.goldenlegprojects.com/no-photo.jpg"},
            {id: 10, name: "Azzam Alderea", position: "Küchenchef", image: "https://cms.goldenlegprojects.com/no-photo.jpg"},
        ]
        this.state = {
            
        }
    }
    render() { 
        return (
            <section id="team" className="line">
                <div className="container">
                    <div className="headline center">
                        <h2>Dieses Team ist unschlagbar im Einsatz für Kunden und Gäste</h2>
                    </div>
                    <div className="team-wrapper">
                        {this.teams.map( (team) =>
                            <TeamItem key={team.id} name={team.name} position={team.position} text={team.text} image={team.image} />
                        )}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Team;