import React, { Component } from 'react';
import MenuList from './MenuList';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import '../assets/css/_menu.scss';
import '../assets/css/hamburgers.min.css';

class MenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuActive: false
        }
        this.menu = [
            { id: 1, title: "Home", link: "/" },
            { id: 2, title: "Privatveranstaltungen", link: "/privatveranstaltungen" },
            { id: 3, title: "Firmenevents", link: "/firmenevents" },
            { id: 4, title: "Veranstalter", link: "/fuer-veranstalter" },
            { id: 5, title: "Eigenmarken", link: "/eigenmarken" },
            { id: 6, title: "Über Uns", link: "/ueber-uns" }
        ];
    }

    componentWillUpdate() {
        if( this.state.menuActive == true ) {
            this.setState({menuActive: false})
        }
    }
    render() { 
        return (
            <section id="menu-wrapper">
                <section id="menu">
                    <div className="container-fluid">
                        <div id="logo">
                            <Link to="/">
                                <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 113.4 113.5">
                                    <g>
                                        <g>
                                            <path class="st0" d="M27.2,19.4h11.1v16.4c-2.2,1.5-4.9,2.7-8.1,3.7c-3.2,1-6.2,1.5-9,1.5c-4.1,0-7.7-0.9-10.9-2.7
                                                c-3.2-1.8-5.7-4.2-7.6-7.3C0.9,27.8,0,24.3,0,20.4C0,16.5,0.9,13,2.8,9.9s4.5-5.5,7.9-7.3C14.1,0.9,17.8,0,22,0
                                                c3,0,6.1,0.6,9.2,1.7c3.1,1.1,5.6,2.6,7.7,4.5L31.6,15c-1.3-1.3-2.8-2.3-4.6-3.1c-1.8-0.8-3.5-1.1-5.1-1.1c-1.6,0-3.1,0.4-4.5,1.3
                                                c-1.3,0.8-2.4,2-3.1,3.4c-0.7,1.5-1.1,3.1-1.1,4.9c0,1.9,0.4,3.5,1.1,5c0.8,1.5,1.8,2.6,3.2,3.5s2.8,1.3,4.5,1.3
                                                c1.4,0,3.1-0.4,5.1-1.3V19.4z"/>
                                        </g>
                                        <g>
                                            <path class="st0" d="M111.8,39.1H98.8V10.8H78.6V0h33.2V39.1z"/>
                                        </g>
                                        <g>
                                            <path class="st0" d="M1.6,74.4h12.9v28.4h20.3v10.8H1.6V74.4z"/>
                                        </g>
                                        <g>
                                            <path class="st0" d="M101.7,92h11.1v16.4c-2.2,1.5-4.9,2.7-8.1,3.7c-3.2,1-6.2,1.5-9,1.5c-4.1,0-7.7-0.9-10.9-2.7
                                                c-3.2-1.8-5.7-4.2-7.6-7.3c-1.8-3.1-2.7-6.6-2.7-10.5c0-3.9,0.9-7.4,2.8-10.5c1.9-3.1,4.5-5.5,7.9-7.3c3.3-1.7,7.1-2.6,11.3-2.6
                                                c3,0,6.1,0.6,9.2,1.7c3.1,1.1,5.6,2.6,7.7,4.5l-7.3,8.9c-1.3-1.3-2.8-2.3-4.6-3.1c-1.8-0.8-3.5-1.1-5.1-1.1
                                                c-1.6,0-3.1,0.4-4.5,1.3c-1.3,0.8-2.4,2-3.1,3.4c-0.7,1.5-1.1,3.1-1.1,4.9c0,1.9,0.4,3.5,1.1,5c0.8,1.5,1.8,2.6,3.2,3.5
                                                c1.3,0.8,2.8,1.3,4.5,1.3c1.4,0,3.1-0.4,5.1-1.3V92z"/>
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                        </div>
                        <nav id="nav-list">
                            <ul className="menu horizontal">
                                {this.menu.map( (menu) =>
                                <li key={menu.id}>
                                    <a href={menu.link} title={menu.title}>{menu.title}</a>
                                </li>
                                )}
                            </ul>
                            <div id="action-button">
                                <a href="mailto:contact@goldenlegprojects.com" className="btn standard">Anfrage</a>
                            </div>
                        </nav>
                        <div className={'hamburger hamburger--collapse' + (this.state.menuActive ? ' is-active' : '')} onClick={() => this.setState({ menuActive: !this.state.active })}>
                            <div className="hamburger-box">
                                <div className="hamburger-inner"></div>
                            </div>
                        </div>
                    </div>         
                </section>
                <MenuList menuItems={this.menu} menuActive={this.state.menuActive}/>
            </section>
        );
    }
}
 
export default MenuBar;