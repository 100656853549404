import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../assets/css/_partner.scss';

import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination]);

class Partner extends Component {
    constructor(props) {
        super(props);
        this.headline = "Unsere starken Partner, die alles geben für unsere Kunden und Gäste";
        this.partners = [
            { id: 1, image: "http://placehold.it/220x90", alt: "Partner" },
            { id: 2, image: "http://placehold.it/220x90", alt: "Partner" },
            { id: 3, image: "http://placehold.it/220x90", alt: "Partner" },
            { id: 4, image: "http://placehold.it/220x90", alt: "Partner" },
            { id: 5, image: "http://placehold.it/220x90", alt: "Partner" },
            { id: 6, image: "http://placehold.it/220x90", alt: "Partner" }
        ]
    }
    render() { 
        return (
            <section id="partner" className="line">
                <div className="container">
                    <div className="headline center">
                        <h2>{this.headline}</h2>
                    </div>
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={4}
                        pagination={{ clickable: true }}
                    >
                        {this.partners.map( (partner) =>
                            <SwiperSlide key={partner.id}>
                                <LazyLoadImage className="img-responsive" alt={partner.alt} src={partner.image} />
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </section>
        );
    }
}
 
export default Partner;