import React, { Component } from 'react';
import MenuBar from './MenuBar';
import '../assets/css/_intro.scss';

class Intro extends Component {
    constructor(props) {
        super(props);
        this.content = {
            title: props.title,
            text: props.text,
            background: props.background
        }
        this.state = {}
    }
    componentDidUpdate() {
        window.scrollTo(0,0);
    }
    componentWillReceiveProps(newProps) {
        this.content = {
            title: newProps.title,
            text: newProps.text
        }
    }
    render() { 
        return (
            <header id="header">
                <MenuBar/>
                <section id="intro" style={{background: "url(" + this.content.background + ") no-repeat center center / cover"}}>
                    <div className="container">
                        <div className="caption-wrapper">
                            <div className="caption-inner">
                                <h1>{this.content.title}</h1>
                                <p>{this.content.text}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </header>
        );
    }
}
 
export default Intro;