import React, { Component } from 'react';
import Services from './Services';

class Pakete extends Component {
    constructor(props) {
        super(props);
        if( props.paket == 'Firma' ) {
            this.pakete = [
                { id: 1, title: "Weihnachtsfeier", description: "Wir haben die passende Location für eure Weihnachtsfeier, mit reichhaltigem Büffet und festlicher Deko, ganz nach euren Wünschen.", image: "https://cms.goldenlegprojects.com/weihnachtsfeier.jpg" },
                { id: 2, title: "Sommerfest", description: "Wir organisieren eure Sommerfeier mit erfrischenden Drinks und gutem Essen, auf Wunsch sogar auf dem Hof eurer Firma.", image: "https://cms.goldenlegprojects.com/sommerfest.jpg" },
                { id: 3, title: "Team-Abend", description: "Wir sorgen für einen besonderen Abend in kleiner Runde, fernab des Arbeitsalltags, für ganz entspanntes Socialising.", image: "https://cms.goldenlegprojects.com/team-abend.jpg" },
                { id: 4, title: "Präsentations-Event", description: "Zeigt eure neuen Ideen und Produkte, wir kümmern uns um dem Rest, von Getränken über Snacks bis zur optimalen Technik.", image: "https://cms.goldenlegprojects.com/praesentations-event.jpg" },
                { id: 5, title: "Jubiläumsfeier", description: "Feiert eure besonderen Erfolge in einer besonderen Umgebung. Wir machen daraus ein Event, das euer Team zusammenschweißt und motiviert.", image: "https://cms.goldenlegprojects.com/jubilaeumsfeier.jpg" },
                { id: 6, title: "After-Work", description: "Nach Feierabend mit Kollegen anstoßen auf einem unserer After-Work-Events in ausgewählten Locations.", image: "https://cms.goldenlegprojects.com/after-work.jpg" }
            ]
        } else {
            this.pakete = [
                { id: 1, title: "Geburtstag", description: "Ob Beach-Bar oder Club – dein Ehrentag fängt gemütlich an und endet wild, ganz individuell nach deinen Wünschen.", image: "https://cms.goldenlegprojects.com/geburtstag.jpg" },
                { id: 2, title: "Hochzeit", description: "Der festlichste Tag deines Lebens wird zu einem unvergesslichen Erlebnis mit unserer perfekten Organisation.", image: "https://cms.goldenlegprojects.com/hochzeit.jpg" },
                { id: 3, title: "Junggesellen-Abschied", description: "Männer feiern ihn wild, Frauen noch wilder, und wir sorgen für die richtigen Drinks.", image: "https://cms.goldenlegprojects.com/junggesellenabschied.jpg" },
                { id: 4, title: "Jugendweihe", description: "Mit Familie und Freunden das Erwachsenwerden angemessen feiern, und wir kümmern uns um alles andere, auch die Technik für die perfekte Rede.", image: "https://cms.goldenlegprojects.com/jugendweihe.jpg" },
                { id: 5, title: "Abi-Ball", description: "Feiert euren Schul-Abschluss und stoßt ein letztes Mal gemeinsam an, in einer unvergesslichen Location.", image: "https://cms.goldenlegprojects.com/abiball.jpg" },
                { id: 6, title: "Familienfeier", description: "In familiärer Atmosphäre gemeinsam Feste feiern: Wir erfüllen jeden Sonderwunsch, für kleine und ganz große Familien.", image: "https://cms.goldenlegprojects.com/familienfeier.jpg" }
            ]
        }
        this.state = {  }
    }
    render() { 
        return (
            <section id="pakete" className="line">
                <div class="headline center">
                    <h2>{this.props.headline}</h2>
                </div>
                <div class="container">
                    <Services boxes={this.pakete}/>
                </div>
            </section>
        );
    }
}
 
export default Pakete;