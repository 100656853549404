import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import axios from 'axios';
import '../assets/css/_contactForm.scss';

const API_PATH = 'https://test.goldenlegprojects.com/mail.php';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            surname: '',
            email: '',
            company: '',
            email: '',
            telephone: '',
            privacy: false,
            privacyError: false,
            mailSent: false,
            error: null,
            message: ''
        }
    }
    handleFormSubmit( event ) {
        event.preventDefault();
        if( this.state.privacy ) {
            axios({
                method: 'post',
                url: `${API_PATH}`,
                headers: { 'content-type': 'application/json' },
                data: this.state
            })
            .then(result => {
                this.setState({
                    privacyError: false,
                    mailSent: result.data.sent,
                    message: result.data.message
                })
            })
            .catch(error => this.setState({ error: error.message }));
        } else {
            this.setState({
                privacyError: true
            });
        }
    }
    render() { 
        return (
            <section id="contact-form" className="line">
                <div className="container">
                    <div className="row">
                        <div className="half">
                            <h2>Erzähl uns über deinen Event</h2>
                            <p>
                                Wie können wir euch dabei unterstützen, euer Ereignis zu einem echten Erlebnis zu machen?
                            </p>
                            <p>
                                Wir melden uns für eine kostenlose Beratung.
                            </p>
                        </div>
                        <div className="half">
                            <form className="contact-form">
                                <div className="row">
                                    <div className="half">
                                        <p>
                                            <label for="surname">
                                                <input type="text" class="form-group" name="surname" placeholder="Name" value={this.state.surname} onChange={e => this.setState({ surname: e.target.value })}/>
                                            </label>
                                        </p>
                                    </div>
                                    <div className="half">
                                        <p>
                                            <label for="firstname">
                                                <input type="text" class="form-group" name="firstname" placeholder="Vorname" value={this.state.firstname} onChange={e => this.setState({ firstname: e.target.value })}/>
                                            </label>
                                        </p>
                                    </div>
                                </div>
                                <p>
                                    <label for="company">
                                        <input id="company" type="text" class="form-group" name="company" placeholder="Firma" value={this.state.company} onChange={e => this.setState({ company: e.target.value })}/>
                                    </label>
                                </p>
                                <p>
                                    <label for="email">
                                        <input id="email" type="text" class="form-group" name="email" placeholder="Email-Adresse" value={this.state.email} onChange={e => this.setState({ email: e.target.value })}/>
                                    </label>
                                </p>
                                <p>
                                    <label for="telephone">
                                        <input id="telephone" type="text" class="form-group" name="telephone" placeholder="Mobil" value={this.state.telephone} onChange={e => this.setState({ telephone: e.target.value })}/>
                                    </label>
                                </p>
                                <p>
                                    <label for="privacy" className="privacy">
                                        <input type="checkbox" id="privacy" class="form-group" name="privacy" onChange={e => this.setState({ privacy: !this.state.privacy })}/>
                                        <p>Ich habe die <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link> gelesen und bestätige sie hiermit.</p>
                                    </label>
                                </p>
                                {!this.state.mailSent &&
                                <p className="text-right">
                                    <input type="submit" value="Anfrage senden"  onClick={e => this.handleFormSubmit(e)} className="btn standard"/>
                                </p>}
                                {this.state.mailSent &&
                                    <p>{this.state.message}</p>
                                }
                                {this.state.privacyError && 
                                    <p>Sie müssen der Datenschutzerklärung zustimmen!</p>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default ContactForm;